<script setup lang="ts">
import SkipToMain from '~/components/layout/external/SkipToMain.vue';
import Header from '~/components/layout/external/Header.vue';
import Footer from '~/components/layout/Footer.vue';
import CookiesAgreementBanner from '~/components/layout/CookiesAgreementBanner.vue';

provide('theme', 'dark');

onMounted(() => {
  document.body.classList.add('dark');
  document.body.classList.remove('light');

  document.body.classList.add('external');
  document.body.classList.remove('internal');
});
</script>

<template>
  <SkipToMain />

  <Header />

  <main id="main">
    <slot />
  </main>

  <Footer />

  <CookiesAgreementBanner />
</template>
